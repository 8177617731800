import Vue from 'vue'
import App from './App.vue'
import vueEsign from 'vue-esign'
import ElementUI from 'element-ui';
import router from './router'
import 'element-ui/lib/theme-chalk/index.css';
import './request/http'
import Vant from 'vant';
import 'vant/lib/index.css';
import "@/tools/directive.js";
// main.js
// import htmlToPdf from './assets/htmlToPdf.js'
// Vue.use(htmlToPdf)
import 'lib-flexible'
import htmlToPdf from './assets/htmlPdf.js'
import './assets/reset.css'
import './assets/border.css'
import './assets/rem'
Vue.use(htmlToPdf)
Vue.use(Vant);
Vue.use(ElementUI);
Vue.use(vueEsign)



Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
