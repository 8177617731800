// 创建 pdf.js
// 引入依赖
import Vue from 'vue'
import html2canvas from 'html2canvas'
import JsPDF from 'jspdf'

const PDF = {}
PDF.install = function (Vue) {
    /**
     * targetDom: 需要打印的dom对象
     * name:pdf的名字
     * callback: 回调函数
     */
    Vue.prototype.$createPdf = function (targetDom) {
        return new Promise((resolve, reject) => {
            let cloneDom = targetDom.cloneNode(true)
            cloneDom.style.width = targetDom.scrollWidth + 'px'
            cloneDom.style.height = targetDom.scrollHeight + 'px'
            cloneDom.style.background = '#FFFFFF'
            document.body.appendChild(cloneDom)
            let scale = 2
            html2canvas(cloneDom, {
                scale: scale, // 提升画面质量，但是会增加文件大小
                useCORS: true, // 允许跨域图片 当图片是链接地址时，需加该属性，否组无法显示图片
                imageTimeout: 0, // 图片加载延迟，默认延迟为0，单位毫秒
                height: targetDom.scrollHeight, // canvas的高度设定
                width: targetDom.scrollWidth, // canvas的宽度设定
                dpi: window.devicePixelRatio * scale, // 将分辨率提高到特定的DPI
            }).then(canvas => {
                document.body.removeChild(cloneDom)
                // a4纸的尺寸[592.28,841.89]
                const A4_WIDTH = 592.28
                const A4_HEIGHT = 841.89
                let contentWidth = canvas.width
                let contentHeight = canvas.height
                let pageData = canvas.toDataURL('image/jpeg', 0.6)
                // 一页pdf html页面生成的canvas高度;
                let pageHeight = contentWidth / A4_WIDTH * A4_HEIGHT
                // 未生成pdf的html页面高度
                let leftHeight = contentHeight
                // 页面偏移
                let position = 0
                // a4纸的尺寸[592.28,841.89]，html页面生成的canvas在pdf中图片的宽高
                let imgWidth = A4_WIDTH
                let imgHeight = A4_WIDTH / contentWidth * contentHeight
              
                // 设置pdf的尺寸，pdf要使用pt单位 已知 1pt/1px = 0.75   pt = (px/scale)* 0.75
                let pdf = new JsPDF('', 'pt', 'a4')
                // 有两个高度需要区分，一个是html页面的实际高度，和生成pdf的页面高度
                // 当内容未超过pdf一页显示的范围，无需分页
                if (leftHeight < pageHeight) {
                    pdf.addImage(pageData, 'JPEG', 20, 20, imgWidth, imgHeight)
                } else {

                    while (leftHeight > 0) {
                        pdf.addImage(pageData, 'JPEG', 0, position, imgWidth, imgHeight)
                        leftHeight -= pageHeight
                        position -= A4_HEIGHT
                        // 避免添加空白页
                        if (leftHeight > 800) {
                            pdf.addPage()
                        }
                    }
                }
                // 直接下载
                // pdf.save(name + '.pdf')
                // base64转成文件
                let buffer = pdf.output('datauristring')
                resolve(buffer)
                // let myfile = dataURLtoFile(buffer, name)
                // callback(myfile)
            }).catch(err=> {
                reject(err)
            })
        })
    }
}
// const dataURLtoFile = (dataUrl, fileName) => {
//     let arr = dataUrl.split(',')
//     let mime = arr[0].match(/:(.*?);/)[1]
//     let bstr = atob(arr[1])
//     let n = bstr.length
//     let u8arr = new Uint8Array(n)
//     while (n--) {
//         u8arr[n] = bstr.charCodeAt(n)
//     }
//     return new File([u8arr], fileName, {
//         type: mime
//     })
// }
Vue.use(PDF)
export default PDF