<template>
    <div id="app">

        <router-view></router-view>
    </div>
</template>

<script>





export default {
    name: 'app',

    data() {
        return {

        }
    },

    mounted() {


    },
    created() {

    },
    methods: {
    },
}
</script>

<style>
    #app {
        width: 375px;
        margin: 0 auto;
    }
</style>
